import React, { forwardRef, ComponentPropsWithRef } from 'react'
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from '@eth-optimism/ui-components/src/components/ui/card/card'
import { Text } from '@eth-optimism/ui-components/src/components/ui/text/text'
import { cn } from '@eth-optimism/ui-components/src/lib/utils'
import { cva, type VariantProps } from 'class-variance-authority'
import { RiArrowRightUpLine } from '@remixicon/react'

const tileVariants = cva('cursor-pointer flex flex-col', {
  variants: {
    variant: {
      primary: 'shadow-sm hover:shadow-md transition-shadow',
      secondary:
        'bg-secondary border-secondary hover:bg-secondary/80 transition-colors',
      featured: 'shadow-sm hover:shadow-md transition-shadow',
      row: 'flex flex-col sm:flex-row w-full p-4 gap-6 h-full',
    },
  },
  defaultVariants: {
    variant: 'primary',
  },
})

type TileProps = {
  title: string
  description?: string
  badge?: React.ReactNode
  icon?: React.ReactNode
  image?: React.ReactNode
  onClick: () => void
  className?: string
  showArrow?: boolean
} & VariantProps<typeof tileVariants> &
  ComponentPropsWithRef<'div'>

const Tile = forwardRef<HTMLDivElement, TileProps>(
  (
    {
      title,
      description,
      badge,
      icon,
      image,
      onClick,
      variant = 'primary',
      className,
      showArrow,
    },
    ref,
  ) => {
    const isFeatured = variant === 'featured'

    return (
      <Card
        className={cn(
          tileVariants({ variant }),
          className,
          'overflow-hidden',
          'group',
        )}
        onClick={onClick}
        ref={ref}
      >
        <div className={cn('relative', isFeatured && 'h-full')}>
          {image && isFeatured && (
            <div className="absolute right-0 bottom-0 hidden md:block">
              {image}
            </div>
          )}
          <div
            className={cn(
              variant === 'row' &&
                'flex flex-col sm:flex-row w-full p-4 gap-6 h-full',
              'flex flex-col h-full',
              isFeatured ? 'w-full md:w-2/3 md:pr-1/3' : 'w-full',
            )}
          >
            {!isFeatured && image && (
              <div className="p-6 pb-4 w-full">{image}</div>
            )}
            <div className="flex gap-2 p-6">
              <div className="flex-1">
                <CardHeader
                  className={cn(
                    'p-0 pb-1 flex-row items-start',
                    Boolean(icon) && 'pr-0',
                    !description && 'pb-6',
                  )}
                >
                  <Text as="span" className="text-base font-semibold flex-1">
                    {title}
                  </Text>
                </CardHeader>
                {description && (
                  <CardContent className={cn('p-0', Boolean(icon) && 'pr-0')}>
                    <Text as="p" className="text-muted-foreground">
                      {description}
                    </Text>
                  </CardContent>
                )}
              </div>
              {icon && <div>{icon}</div>}
            </div>
            {badge && <CardFooter className="mt-auto">{badge}</CardFooter>}
          </div>
          {showArrow && (
            <div className="absolute top-4 right-4">
              <RiArrowRightUpLine className="w-5 h-5 opacity-0 group-hover:opacity-100 transition-opacity" />
            </div>
          )}
        </div>
      </Card>
    )
  },
)

Tile.displayName = 'Tile'

type TileGridProps = {
  children: React.ReactNode
  columns: 1 | 2 | 3
}

const TileGrid = ({ children, columns }: TileGridProps) => {
  return (
    <div
      className={`grid grid-cols-1 gap-6 auto-rows-[205px] md:grid-cols-${columns} lg:grid-cols-${columns}`}
    >
      {children}
    </div>
  )
}

export { Tile, TileGrid }
