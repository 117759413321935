'use client'

import Image from 'next/image'
import { Tile, TileGrid } from '@/app/components/Tile/Tile'
import { Text } from '@eth-optimism/ui-components/src/components/ui/text/text'
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from '@eth-optimism/ui-components/src/components/ui/dialog/dialog'
import { useState } from 'react'
import { useDialogContent } from '@/app/console/useDialogContent'
import { trackCardClick } from '@/app/event-tracking/mixpanel'
import { SuperchainSafeModalContent } from '@/app/console/SuperchainSafeModalContent'
import { useRouter } from 'next/navigation'
import { useFeatureFlag } from '@/app/hooks/useFeatureFlag'
import { Badge } from '@eth-optimism/ui-components'
import { openWindow } from '@/app/helpers'
import { externalRoutes } from '@/app/constants'

const BuildSection = () => {
  const [dialogContent, setDialogContent] = useState<React.ReactNode>()
  const { testnetPaymasterContent, quickStartContent } = useDialogContent()
  const superchainSafeContent = <SuperchainSafeModalContent />
  const router = useRouter()
  const showStarterKit = useFeatureFlag('enable_starterkit_devconsole')

  // Event handler for clicking on the Faucet tile
  const handleFaucetClick = () => {
    trackCardClick('Superchain Faucet')
    router.push('/faucet')
  }

  // Event handler for clicking on the StarterKit tile
  const handleStarterKitClick = () => {
    trackCardClick('SuperchainERC20 starter kit')
    router.push('/build')
  }

  // Event handler for clicking on the App Template tile
  const handleAppTemplatesClick = () => {
    trackCardClick('App template library')
    router.push('/templates')
  }

  return (
    <div>
      <Text as="h3" className="text-2xl font-semibold mb-4">
        Build
      </Text>
      <Dialog>
        {showStarterKit ? (
          <div className="flex flex-col gap-4">
            <TileGrid columns={1}>
              <Tile
                title="SuperchainERC20 Starter Kit"
                description="Deploy the SuperchainERC20 token to enable interoperable assets in your app from day one of Superchain Interop. SuperchainERC20 is an implementation of ERC-7802 for seamless cross-chain asset functionality."
                onClick={handleStarterKitClick}
                image={
                  <Image
                    src="/banners/starterkit-character.png"
                    alt="Sunny Graphic"
                    width={165}
                    height={161}
                    className="object-contain"
                  />
                }
                variant="featured"
                badge={<Badge variant="success">Featured</Badge>}
              />
            </TileGrid>
            <TileGrid columns={3}>
              <Tile
                title="Superchain Multi-Chain Faucet"
                description="Get test ETH tokens to build your app on the Superchain."
                onClick={handleFaucetClick}
              />
              <Tile
                title="Supersim"
                description="Build and test apps in a local multi-chain development environment."
                onClick={() => {
                  trackCardClick('Supersim')
                  openWindow(externalRoutes.SUPERSIM.path)
                }}
                showArrow={true}
                badge={<Badge>New</Badge>}
              />
              <Tile
                title="App Template Library"
                description="Check out demo apps that highlight Superchain’s cross-chain capabilities."
                onClick={handleAppTemplatesClick}
                badge={<Badge>New</Badge>}
              />
            </TileGrid>
          </div>
        ) : (
          <TileGrid columns={3}>
            <Tile
              title="Superchain Faucet"
              description="Get test ETH tokens to build your app on the Superchain."
              onClick={handleFaucetClick}
            />
            <DialogTrigger asChild>
              <Tile
                title="Testnet Paymaster"
                description="Integrate smart accounts and get your testnet transactions sponsored."
                onClick={() => {
                  trackCardClick('Testnet Paymaster')
                  setDialogContent(testnetPaymasterContent)
                }}
              />
            </DialogTrigger>
            <DialogTrigger asChild>
              <Tile
                title="Superchain Safe"
                description="Get multisig support on any OP Chain in the Superchain."
                onClick={() => {
                  trackCardClick('Superchain Safe')
                  setDialogContent(superchainSafeContent)
                }}
              />
            </DialogTrigger>
            <DialogTrigger asChild>
              <Tile
                title="Quick Start"
                description="Deploy an app on the Superchain in under 15 minutes."
                onClick={() => {
                  trackCardClick('Quick start')
                  setDialogContent(quickStartContent)
                }}
              />
            </DialogTrigger>
          </TileGrid>
        )}
        <DialogContent className="w-full max-w-[448px]">
          {dialogContent}
        </DialogContent>
      </Dialog>
    </div>
  )
}

export { BuildSection }
